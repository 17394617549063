import { DataModel, core, } from "@essenza/react";

export function RealeModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, RealeModel, {
    etype: "deal",
    
    search(value) {
        return this.ExecuteQuery("reale_search", {text: value});
    },

    match(reale){
        if(reale.kind === "Vendita"){
            const data = null; // Devo creare data da reale (se voglio creare una class reale??? => definisco in schema!!!)
            return this.match_request(reale);
        }
        else if(reale.kind === "Acquisto"){
            const data = null; // Devo creare data da reale
            return this.match_reale(reale);
        }
        else{
            return Promise.reject("Il match è applicabile solo a trattative di acquisto o di vendita")
        }
    },
    
    match_request(data){
        return this.ExecuteQuery("match_req", data);
    },
    
    match_reale(data){
        return this.ExecuteQuery("match_reale", data);
    }
});