import { useVista, Vista, core, ViewModel, useModel, useData } from "@essenza/react";
import { RealeModel } from "../data/reale";
import { SearchInput } from "../component/searchinput";
import { Loader } from "../component/loader";
import { IT } from "../core/utils";
import { MatchTable } from "../part/reale/matchtable";

export function MatchVista() {
    const vm = useVista(MatchVVM);
    const [model, data] = useModel(RealeModel);

    //if (!data) return <span>Nessun immobile corrispondente</span>
    const onsearch = v => {
        console.log(v);
        model.search(v);
        vm.selected = null;
        vm.update();
    }

    const reale = vm.selected;

    return (
        <Vista>
            <div className="p-3">
                <div className="bg-white rounded-xl p-3">
                    <SearchInput values={model.pending ? null : data} onSearch={onsearch} labelField="dealname" onSelect={v => vm.onSelect(v)} title={false} placeholder="Ricerca trattativa" />
                    {model.pending && <Loader className="mt-12" />}
                </div>
                {
                    reale &&
                    <>
                        {/* <h1 className="text-lg font-bold my-4">{reale.dealname}</h1> */}

                        <div className="bg-white rounded-md p-3 my-3">
                            <h1 className="text-lg font-bold ml-1">{reale.dealname}</h1>
                            <h3 className="my-1 ml-1">{reale.description}</h3>
                            {/* <h3 className="my-2">Zona</h3>
                            <h3 className="my-2">{reale.city}</h3> */}

                            <div className="flex gap-3 P-3">
                                <div className="bg-gray-100  h-20 p-4 rounded-xl">
                                    <h1>ZONA</h1>
                                    <h1 className="text-2xl font-bold">{reale.city}</h1>
                                </div>
                                <div className="bg-gray-100 h-20 p-4 rounded-xl">
                                    <h1>TIPO</h1>
                                    <h1 className="text-2xl font-bold">{reale.purpose}</h1>
                                </div>
                                <div className="bg-gray-100 w-48 h-20 p-4 rounded-xl">
                                    <h1>PREZZO</h1>
                                    <h1 className="text-2xl font-bold">{IT.currency(reale.budget)}</h1>
                                </div>
                                <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl">
                                    <h1>MQ</h1>
                                    <h1 className="text-2xl font-bold">{IT.decimal(reale.mq)}</h1>
                                </div>
                            </div>
                        </div>
                        <MatchTable source={data} />
                        {/* 
                         -- DATI REALE (mq, city, address, budget,  description)
                         -- TABLE con elenco immobili o richieste del match con score e indicazioni dei vari parametri del match anche con colori (verde, giallo, arancio)
                         */ }
                    </>
                }
            </div>
        </Vista>
    )
}

export function MatchVVM() {
    ViewModel.call(this);
    this.selected = null;
    //this.use(LandlordmapVM).listen("SELECT");
    //this.use(EinvoicecardVM).as("card");
}

core.prototypeOf(ViewModel, MatchVVM, {
    onSelect(v) {
        console.log("MATCH SELECTED", v);
        this.selected = v;
        this.$deal.match(v).catch(e => {
            console.log("MATCH SELECTED ERROR", e);
            //Visualizzo motivo per cui non è andato a buon fine...
            //this.update();
        });
    },
    intent: {
    }
});

/* export function MatchVista2() {
    const vm = useVista(MatchVVM2);
    const [data, pending] = useData(vm.model); // se voglio usare rerender tramite stato, altrimenti uso  direttamente vm
    const { reale, onsearch, onselect } = vm;

    return (
        <Vista>
            <div className="p-3">
                <div className="bg-white rounded-xl p-3">
                    <SearchInput values={pending ? null : data} onSearch={onsearch} labelField="dealname" onSelect={onselect} title={false} placeholder="Ricerca trattativa" />
                    {pending && <Loader className="mt-12" />}
                </div>
                {
                    reale &&
                    <>
                       

                        <div className="bg-white rounded-md p-3 my-3">
                            <h1 className="text-lg font-bold ml-1">{reale.dealname}</h1>
                            <h3 className="my-1 ml-1">{reale.description}</h3>


                            <div className="flex gap-3 P-3">
                                <div className="bg-gray-100  h-20 p-4 rounded-xl">
                                    <h1>ZONA</h1>
                                    <h1 className="text-2xl font-bold">{reale.city}</h1>
                                </div>
                                <div className="bg-gray-100 h-20 p-4 rounded-xl">
                                    <h1>TIPO</h1>
                                    <h1 className="text-2xl font-bold">{reale.purpose}</h1>
                                </div>
                                <div className="bg-gray-100 w-48 h-20 p-4 rounded-xl">
                                    <h1>PREZZO</h1>
                                    <h1 className="text-2xl font-bold">{IT.currency(reale.budget)}</h1>
                                </div>
                                <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl">
                                    <h1>MQ</h1>
                                    <h1 className="text-2xl font-bold">{IT.decimal(reale.mq)}</h1>
                                </div>
                            </div>
                        </div>
                        <MatchTable source={data} />
                    </>
                }
            </div>
        </Vista>
    )
} */
/*
//TODO: va registrato (Map) VM globally in context (o in scope)
const MatchVVM2 = ViewModel.create({
    //"@inject": [],
    "@shared": { person: MatchVVM},

    $$constructor() {
        this.model = this.inject(RealeModel);
        this.reale = null;
    },

    onselect(v) {
        this.reale = v;
        this.model.match(v).catch(e => {
            //Visualizzo motivo per cui non è andato a buon fine...
            //this.update();
        });
    },

    onsearch(v) {
        console.log(v);
        this.reale = null;
        this.model.search(v);
        this.update();
    },

    "@observe": { 
        INVOICE_FOR({ data }) {
            const item = data.item;
            item.$invfor = data.value;
            item.isMutated && item.save();
        }
    },
});*/
