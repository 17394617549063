import React, { useEffect, useMemo, } from "react";
import { useModel, useWidget, Widget, core, ViewModel, } from "@essenza/react";
import { Table, Button } from "antd";
import { SiMicrosoftexcel } from "react-icons/si";
import { EInvoiceModel } from "../../data/einvoice";
import { IT } from "../../core/utils";

export function EinvDetail({ info }) {
	const vm = useWidget(EinvDetailVM);
	const [model, data] = useModel(EInvoiceModel);
	useEffect(() => {
		model.detail(info);
	}, [model])

	const columns = useMemo(() => cols(vm), [vm])

	return (<Widget>
		<Button onClick={() => vm.emit("EXPORT", info)} icon={<SiMicrosoftexcel />}>Export Excel</Button>
		<Table rowKey="id" dataSource={data} columns={columns} pagination={false}>
		</Table>
	</Widget>)
}

function EinvDetailVM() {
	ViewModel.call(this);
}

core.prototypeOf(ViewModel, EinvDetailVM, {
	intent: {
		EXPORT({ data }) {
			console.log(data);
			this.$einvoice.export(data).then(r=>window.open(r.data, '_blank'));
		},
	}
});

function cols(vm) {
	return [{ title: "Numero", dataIndex: "kbnum", key: "id" },
	{ title: "Checkin", dataIndex: "checkin", key: "id", render: text => IT.date(text) },
	{ title: "Checkout", dataIndex: "checkout", key: "id", render: text => IT.date(text) },
	{ title: "Camere", dataIndex: "roomname", key: "id" },
	{ title: "Riferimento", dataIndex: "referent", key: "id" },
	{ title: "Ospiti", dataIndex: "guests", key: "id" },
	{ title: "Canale", dataIndex: "channel", key: "id" },
	// { title: "Codice OTA", dataIndex: "idota", key: "id"},
	{ title: "Addebito", dataIndex: "gross", key: "id", render: text => IT.currency(text) },
	{ title: "Commissioni", dataIndex: "commission", key: "id", render: text => IT.currency(text) },
	{ title: "Ricevuto", dataIndex: "netota", key: "id", render: text => IT.currency(text) },
	]
}