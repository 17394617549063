import { DataModel, core, } from "@essenza/react";

export function InvoiceModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, InvoiceModel, {
    etype: "invoice",
    
    list(state) {
        return this.ExecuteQuery("inv_list", {state});
    },

    search(text) {
        return this.ExecuteQuery("inv_search", {text});
    },
    
    flist(state) {
        return this.ExecuteQuery("inv_flist", {state});
    },
});