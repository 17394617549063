import { useApp, useVM, ViewModel, core } from "@essenza/react";
import { Outlet } from "react-router-dom";
import { Avatar, Col, Layout, Menu, Row, Tooltip, notification } from "antd";
import React from 'react';
import { FiUser } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { TbSquareKey } from "react-icons/tb";
import { GoHome } from "react-icons/go";

import { MdQuestionMark } from "react-icons/md";
import { BsQuestionSquare } from "react-icons/bs";
import { FaChartSimple } from "react-icons/fa6";
import { FiKey } from "react-icons/fi";
//import { ReactComponent as User} from '../assets/icon/icon_user.svg';
import LogoSrc from "../assets/img/logobianco.png";
import { SelectableGroup } from "../component/selectable";
import { IoDocuments } from "react-icons/io5";
import { TbFileEuro } from "react-icons/tb";
import { TbHomeSearch } from "react-icons/tb";
const { Header, Content } = Layout;

export function BaseLayout() {
    const app = useApp();
    const vm = useVM(LayoutVM);

    if(!app.logged){
        app.navigate("login");
        return null;
    }
    const authorized = app.role.authorize("ADMIN,USER");
    console.log("PASSA LAYOUT", app.logged);
    return (
        <Layout className="bg-bgpri rounded-md  flex w-full h-screen">
            {/* <Layout className="bg-gradient-to-t bg-[#002a3a] h-screen py-4 px-2 md:px-4 ">
            <div className="flex items-center px-4 md:px-10 pt-1 static top-0 w-full z-0">
        <img src={Logo} alt="Logo" className="max-h-6 flex-none" />
        <TbBrandGoogleHome className="flex-none text-white text-2xl cursor-pointer ml-4" onClick={() => app.navigate("home")} />

        <Menu className="bg-transparent text-white font-bold flex-auto min-w-0" onClick={e => vm.emit("MENU_NAV", e.key)} mode="horizontal" items={vm.items} />

        { app.role.authorize("ADMIN") && <IoMdSettings className="flex-none text-white text-2xl cursor-pointer mr-2" onClick={() => app.navigate("settings")} /> }
        <FaUser onClick={() => app.navigate("profile")} className="flex-none text-white text-2xl cursor-pointer" />
      </div> 
      <div className="px-2 md:px-4 py-4 w-full h-full bg-[#f1f4f9] rounded-md overflow-auto flex">
      */}

            <div >
                <SelectableGroup className="flex items-center bg-[#342C2C] gap-5 min-w-16 py-1 px-4 text-2xl text-white">
                    <img src={LogoSrc} className="max-w-8" alt="Logo" nolink />
                    <div className="flex-auto" nolink></div>
                    <GoHome className="flex-none text-2xl cursor-pointer" onClick={() => app.navigate("home")} />
                    {authorized && <FiKey className="flex-none text-2xl cursor-pointer" onClick={() => app.navigate("properties")} />}
                    <FaChartSimple className="flex-none cursor-pointer" onClick={() => app.navigate("dashboard")} />
                    <IoDocuments className="flex-none cursor-pointer" onClick={() => app.navigate("docs")} />
                    <TbFileEuro className="flex-none cursor-pointer" onClick={() => app.navigate("invreq")} />
                    <div className="flex-auto"></div>
                    <TbHomeSearch className="flex-none cursor-pointer" onClick={() => app.navigate("match")} />
                    <Tooltip title="Guida" color="#FAC710" placement="bottom"><MdQuestionMark aaa="prova" className="flex-none cursor-pointer text-2xl" onClick={() => notification.info({ message: "Disponibile a breve.", duration: 2 })} /></Tooltip>
                    {authorized && <Tooltip title="Gestione Utenti" color="#FAC710" placement="bottomLeft"><IoSettingsOutline className=" cursor-pointer" onClick={() => app.navigate("settings")} /></Tooltip>}
                    <FiUser onClick={() => app.navigate("profile")} className="cursor-pointer" />
                </SelectableGroup>
            </div>

            <div className="overflow-auto h-main">
                <Outlet></Outlet>
            </div>
        </Layout>
    );
}
//grigio: #636c78 - [#dbe5e7]
function LayoutVM() {
    ViewModel.call(this);
    this.current = "agenda";
    this.items = [
        {
            label: 'PROPRIETA',
            key: 'home',
            //icon: <MailOutlined />,
        },
        {
            label: 'FATTURE',
            key: 'settings',
            //icon: <AppstoreOutlined />,
        },
        {
            label: 'DOCUMENTI',
            key: 'profile',
            //icon: <AppstoreOutlined />,
        },
    ];
}

core.prototypeOf(ViewModel, LayoutVM, {
    intent: { //swipe or override
        MENU_NAV: function ({ context, data }) {
            this.current = data;
            context.navigate(data);
        },
    }
});