import React, { useEffect } from "react";
import { useWidget, Widget, core, ViewModel, useModel } from "@essenza/react";
import { PropertyModel } from "../../data/property";


export function Rating({ info }) {
    const vm = useWidget(RatingVM);

    const [model, data] = useModel(PropertyModel);
    useEffect(() => {
        model.rating(info);
    }, [model])

    if (!data) return null;
    else if (data.value === 0) return <div className="text-md">Nessuna recensione nel periodo selezionato</div>;
    return (<Widget>
        <div className="text-md font-bold">Recensioni: {data.value}</div>
        <div classname="w-full">
            <div classname="flex flex-row">
                <div className="flex gap-3 mt-3">
                    <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl">
                        <h1>GENERALE</h1>
                        <h1 className="text-2xl font-bold">{data.rating}</h1>
                    </div>
                    <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl">
                        <h1>PULIZIA</h1>
                        <h1 className="text-2xl font-bold">{data.cleaning}</h1>
                    </div>
                    <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl">
                        <h1>COMFORT</h1>
                        <h1 className="text-2xl font-bold">{data.comfort}</h1>
                    </div>
                </div>
                <div className="flex gap-3 mt-3">
                    <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl">
                        <h1>SERVIZI</h1>
                        <h1 className="text-2xl font-bold">{data.facilities}</h1>
                    </div>
                    <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl">
                        <h1>LOCATION</h1>
                        <h1 className="text-2xl font-bold">{data.location}</h1>
                    </div>
                    <div className="bg-gray-100 w-36 h-20 p-4 rounded-xl">
                        <h1>COMUNICAZ.</h1>
                        <h1 className="text-2xl font-bold">{data.cleaning}</h1>
                    </div>
                </div>
            </div>
        </div>
    </Widget>)
}

export function RatingVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, RatingVM, {
    $intent: {

    }
});