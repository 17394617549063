import { Progress, Table, Tooltip } from "antd";
import { MdOutlineOpenInNew } from "react-icons/md";
import { MdOutlinePlace } from "react-icons/md";
import { BiEuro } from "react-icons/bi";
//import { TbHomeFilled } from "react-icons/tb";
import { BsHouseCheck } from "react-icons/bs";
import { IT } from "../../core/utils";

export function MatchTable({ source, vm }) {
    return (
        <Table rowKey="id" dataSource={source} columns={cols(vm)} pagination={{ defaultPageSize: 10, pageSize: 10 }}>
        </Table>
    )
}
const conicColors = {
    '0%': '#dc2626',
    '50%': '#facc15',
    '100%': '#a3e635',
  };
function cols(vm) {
    return [
        { title: "Nome", dataIndex: "dealname", key: "id", width: 180 },
        { title: "Budget", dataIndex: "budget", key: "id", width: 180, render: (text, record) => IT.currency(text) },
        { title: "Luogo", dataIndex: "city", key: "id", width: 180 },
        { title: "Mq", dataIndex: "mq", key: "id", width: 180 },
        { title: "Tipologia", dataIndex: "purpose", key: "id", width: 180 },
        // { title: "Descrizione", dataIndex: "id", key: "id", width: 180 },
        {
            title: "Affinità", key: "id", render: (text, record) => {
                return (
                    <div className="flex gap-2">
                        <Progress type="circle" strokeColor={conicColors}  size="small" percent={parseInt(record.score)} />
                        <div className="w-28">
                            <div className="flex items-center"><BiEuro className="text-xl" /><Progress size="small" percent={parseInt(record.match_price)} /></div>
                            <div className="flex items-center"><MdOutlinePlace className="text-xl" /><Progress strokeColor={conicColors} size="small" percent={parseInt(record.match_place)} /></div>
                            <div className="flex items-center"><BsHouseCheck className="text-lg" /><Progress size="small" percent={parseInt(record.match_mq ? 100 + record.match_mq : 100)} /></div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: " ", key: "id", render: (text, record) => {
                return (
                    <Tooltip title="Dettaglio" placement="bottom">
                        <MdOutlineOpenInNew className="text-2xl cursor-pointer" onClick={(e) => { vm.emit("OPEN", record); }} />
                    </Tooltip>);
            },
        }
    ]
}