import { useWidget, Widget, core, ViewModel, useModel } from "@essenza/react";
import { Menu } from "../../component/menu";
import { EInvoiceView, IEinvoiceVM } from "./view";
import { useEffect, useState } from "react";
import { IT } from "../../core/utils";
import { EInvoiceModel } from "../../data/einvoice";
import { Loader } from "../../component/loader";
import { IoEyeOutline } from "react-icons/io5";
import { InvoiceModel } from "../../data/invoice";
import { EnvoicePreview, EnvoiceVM } from "./preview";
import { Input } from "antd";

export function EInvoiceList({ report }) {
    const vm = useWidget(InvoiceVM);
    const [model, list] = useModel(EInvoiceModel);

    model.request(m => {
        vm.index = 0;
        m.list(vm.selected.value);
    }, [vm.selected.value, model])

    vm.list = list;

    return (
        <Widget>
            <div className="flex flex-col h-full">
                <div className="flex gap-3 items-center">
                    <h1 className="font-bold text-lg">Elenco attività da Fatture</h1>
                    <Menu source={vm.items} lite itemClick={(_, item) => { vm.select(item); }} />
                </div>
                {
                    model.pending ? <Loader className="mt-12" /> :
                        (list && list.length > 0 ?
                            <div className="flex gap-3 flex-1 h-[calc(100vh-220px)] bg-white rounded-2xl p-4 mt-4 font-semibold">
                                <div className="basis-2/5 max-h-lvh overflow-auto">
                                    <div className="flex w-full pt-2 px-4 ">
                                        {/* <div className="basis-1/5">Tipo</div> */}
                                        <div className="basis-1/4">Rif.</div>
                                        <div className="basis-1/4">Data</div>
                                        <div className="basis-2/4">Descrizione</div>
                                    </div>
                                    {
                                        list.map((item, i) => <div onClick={() => vm.setIndex(i)} className={"flex  w-full py-2 px-4 mt-2 rounded-xl cursor-pointer " + (i === vm.index ? "bg-dark text-white" : "bg-gray-100")}>
                                            {/* <div className="basis-1/5">{vm.kind[item.invoicing]}</div> */}
                                            <div className="basis-1/4">{item.num}</div>
                                            <div className="basis-1/4">{IT.date(item.date)}</div>
                                            <div className="basis-2/4">{item.title}</div>
                                        </div>)
                                    }
                                </div>
                                <div className="basis-3/5 bg-gray-100 rounded-2xl py-3 px-6 max-h-lvh overflow-auto">
                                    {/* {list ? <EInvoiceView item={list[vm.index]} /> : null} */}
                                    {list ? <EnvoicePreview data={list[vm.index]} /> : null}
                                    
                                </div>
                            </div> : "Nessuna attività " + vm.selected.label)
                }
            </div>
        </Widget>)
}

export function InvoiceVM() {
    ViewModel.call(this);
    this.items = [
        { label: 'Da Risolvere', value: 3 },
        { label: 'Da Approvare', value: 4 },
        { label: 'Approvate', value: 5 },
        { label: 'Sospese', value: 6 },
        { label: 'Archiviate', value: 7 },
        { label: 'In Elaborazione', value: 2},
    ];
    this.index = 0;
    this.kind = { F: "Proprietario FM", O: "Ospite", C: "Commissione" }
    this.selected = this.items[0];
    this.use(EnvoiceVM).listen("CHANGE_STATE", this);
}

core.prototypeOf(ViewModel, InvoiceVM, {
    select(item) {
        this.selected = item;
        this.update();
    },

    setIndex(index) {
        this.index = index;
        this.update();
    },

    intent: { //swipe or override
        CHANGE_STATE({ data }) {
            this.index = 0;
            this.$einvoice.remove(data.item);
        },

        SEARCH({ data }) {
            console.log("SEARCH VALUE", data);
            this.$invoice.search(data);
        },
    }
});


export function InvoiceList({ state }) {
    const vm = useWidget(InvoiceVM);
    const [model, list] = useModel(InvoiceModel);

    model.request(m => {
        vm.index === 0 ? m.list() : m.flist();
    }, [model, vm.index])

    const items = [
        { label: 'Emesse', value: 0 },
        { label: 'Fatture Podere Raggio di sole', value: 1 },
        // { label: 'Transazioni senza Etichetta', value: 2 },
    ];

    return (
        <Widget>
            <div className="flex flex-col h-full">
                <div className="flex gap-3 items-center" >
                    <h1 className="font-bold text-lg">Elenco Fatture</h1>
                    <Menu source={items} lite itemClick={(i, item) => { vm.index = i; vm.update()}} />
                    <Input  className="px-6 rounded-full  bg-white max-w-72" style={{ backgroundColor: "white" }} onChange={e => vm.text = e.target.value} />
                    <button className="btn-dark bg-dark rounded-full" onClick={() => vm.emit("SEARCH", vm.text)}>
                        Cerca
                    </button>
                </div>
                <div className="flex-1 bg-white rounded-2xl p-3 mt-4 font-semibold">
                    <div className="basis-3/5 max-h-lvh overflow-auto">
                        <div className="flex w-full py-2 px-4 bg-dark text-white rounded-xl">
                            <div className="basis-1/6">Data</div>
                            <div className="basis-1/6">Numero</div>
                            <div className="basis-2/6">Descrizione</div>
                            <div className="basis-1/6">Destinatario</div>
                            <div className="basis-1/6">Importo</div>
                            <div className="basis-1/6">Stato</div>
                        </div>
                        {
                            model.pending ? <Loader className="mt-12" /> : (list && list.map((item, i) => {
                                const declined = item.einvoicing_status === 'declined';
                                return item ? <div className="flex  w-full py-2 px-4 mt-2 rounded-xl bg-gray-100" >
                                    <div className="basis-1/6">{item.issue_date}</div>
                                    <div className="basis-1/6">{declined ? "Annullata" : item.number}</div>
                                    <div className="basis-2/6">{item.description}</div>
                                    <div className="basis-1/6">{item.taxable}</div>
                                    <div className="basis-1/6">{IT.currency(item.total_amount_cents / 100)}</div>
                                    <div className="basis-1/6 flex">
                                        <h1 className={"flex-auto text-" + (item.einvoicing_status || 'black')}>{item.einvoicing_status.toUpperCase()}</h1>
                                        {!declined && <IoEyeOutline onClick={() => window.open(item.invoice_url, '_blank')} className="text-xl cursor-pointer" />}
                                    </div>
                                </div> : <h1>Nessuna fattura da visualizzare</h1>
                            }))
                        }
                    </div>
                </div>
            </div>
        </Widget>)
}